import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as OfficeActions from '../actions/offices.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { OfficesService } from '../../services';

@Injectable()
export class OfficesEffects {
  constructor(private actions$: Actions, private PageService: OfficesService) {}

  LoadBugs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OfficeActions.LoadOffices),
      switchMap(() =>
        this.PageService.getOffices().pipe(
          map(Offices => OfficeActions.LoadOfficesSuccess({ Offices })),
          catchError(error => of(OfficeActions.LoadOfficesFail({ error })))
        )
      )
    );
  });
}
