import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PeopleRoutingModule } from './people-routing.module';

import { CoreModule } from '../core/core.module';
import { IconModule } from '@teamfoster/sdk/icon';
import { PeopleDetailComponent } from './containers/people-detail/people-detail.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { PeopleEmployeesComponent } from './components/people-employees/people-employees.component';
import { ContentBlockModule } from '../content-block/content-block.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromPeople from './store';
import { FiltersModule } from '../filters/filters.module';
import { ReactiveFormsModule } from '@angular/forms';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { ArrayUtilityModule } from '@teamfoster/sdk/array-utility';

@NgModule({
  declarations: [PeopleDetailComponent, PeopleEmployeesComponent],
  imports: [
    CommonModule,
    PeopleRoutingModule,
    CoreModule,
    IconModule,
    DictionaryNgrxModule,
    InViewModule,
    ContentBlockModule,
    FiltersModule,
    TextUtilityModule,
    ArrayUtilityModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromPeople.featureKey, fromPeople.reducers, {
      metaReducers: fromPeople.metaReducers,
    }),
    EffectsModule.forFeature(fromPeople.effects),
  ],
  exports: [PeopleEmployeesComponent],
})
export class PeopleModule {}
