import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromOffices from '../reducers/offices.reducer';
import { Office } from '../../models';

export const getOfficeState = createSelector(fromFeature.getPeopleState, (state: fromFeature.PeopleState) => state.offices);

export const getOfficeEntities = createSelector(getOfficeState, fromOffices.getOfficesEntities);

export const getAllOffices = createSelector(getOfficeEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedOffices = createSelector(getAllOffices, (Pages: Office[]) => {
  const orderedPages = Pages.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return orderedPages;
});

export const getOfficesLoaded = createSelector(getOfficeState, fromOffices.getOfficesLoaded);
export const getOfficesLoading = createSelector(getOfficeState, fromOffices.getOfficesLoading);
