import { trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  HostListener,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CardGridAnimation } from '../../../animations/card-grid.animation';
import { FilterConfig } from '../../../filters/models';

import { Employee } from '../../models/employee';

@Component({
  selector: 'app-people-employees',
  templateUrl: './people-employees.component.html',
  animations: [trigger('CardGridAnimation', [CardGridAnimation])],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleEmployeesComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() items: Employee[];
  @Input() asContacts: boolean;
  @Input() isContainer = true;
  @Input() cols = 4;
  @Input() filters: FilterConfig[];

  @Output() filterChange = new EventEmitter<{ [key: string]: string | number }>();
  @ViewChild('employees') el: ElementRef;
  $unsubscribe = new Subject<void>();
  sortBy = 'name';
  take = 12;
  page = 1;
  hasFilters = false;

  sortForm = this.fb.group({
    sort: ['name', Validators.required],
  });

  constructor(@Inject(PLATFORM_ID) private platformId, private fb: UntypedFormBuilder, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.sortForm.valueChanges.pipe(takeUntil(this.$unsubscribe)).subscribe(val => {
      // console.log(val);
      this.sortBy = val.sort;
    });
  }

  ngAfterViewInit() {
    this.setMainMinHeight();

    this.route.fragment.pipe(takeUntil(this.$unsubscribe)).subscribe(hash => {
      // console.log(hash);
      if (hash === 'team') {
        setTimeout(() => {
          this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 600);
      }
    });
    // check if filter or search and scroll to employees
    if (Object.entries(this.route.snapshot.queryParams).length && isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 600);
    }
  }

  setMainMinHeight() {
    if (isPlatformBrowser(this.platformId)) {
      const elFilter = document.querySelector('.filter-bar') ? document.querySelector('.filter-bar') : false;
      if (elFilter) {
        this.el.nativeElement.style.setProperty('--projectsMinHeight', `${elFilter.clientHeight}px`);
      }
    }
  }

  ngOnDestroy() {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  changeFilters($e) {
    const filters: any = $e;
    this.filterChange.emit($e);
    if (filters.position || filters.location) {
      this.hasFilters = true;
    } else {
      this.hasFilters = false;
    }
  }

  moreEmployees($e) {
    if ($e) {
      this.page = this.page + 1;
      this.take = this.take * this.page;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setMainMinHeight();
  }
}
