import { createAction, props } from '@ngrx/store';
import { Employee } from '../../models';

export const LoadEmployees = createAction('[People] Load Employees');
export const LoadEmployeesSuccess = createAction('[People] Load Employees Success', props<{ Employees: Employee[] }>());
export const LoadEmployeesFail = createAction('[People] Load Employees Fail', props<{ error: any }>());

export const LoadContactEmployees = createAction('[People] Load Contact Employees');
export const LoadContactEmployeesSuccess = createAction('[People] Load Contact Employees Success', props<{ Employees: Employee[] }>());
export const LoadContactEmployeesFail = createAction('[People] Load Contact Employees Fail', props<{ error: any }>());

export const ClearEmployees = createAction('[People] Clear Employees');
