import { createReducer, on } from '@ngrx/store';
import { Employee } from '../../models';
import * as pageActions from '../actions/employees.action';
import * as utility from '../../../utility';

export interface EmployeesState {
  entities: { [id: number]: Employee };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: EmployeesState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const EmployeeReducer = createReducer(
  initialState,
  on(pageActions.LoadEmployees, (state: EmployeesState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadEmployeesSuccess, (state: EmployeesState, { Employees }) => {
    const entities = utility.ToEntities(Employees, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadEmployeesFail, (state: EmployeesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(pageActions.ClearEmployees, (state: EmployeesState) => {
    return {
      ...initialState,
    };
  })
);

export const getEmployeesEntities = (state: EmployeesState) => state.entities;
export const getEmployeesLoading = (state: EmployeesState) => state.loading;
export const getEmployeesLoaded = (state: EmployeesState) => state.loaded;
