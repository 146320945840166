<section class="people-employees" [ngClass]="{ 'spacer-top-xl': filters }" #employees id="team" [class.has--top-spacing]="isContainer">
  <aside class="spacer-bottom-s people-employees__filters" [class.set--active-filter]="hasFilters" *ngIf="filters">
    <div class="people-employees__filters__wrapper">
      <div class="container-fluid">
        <app-filter-bar [filters]="filters" (filterChange)="changeFilters($event)"></app-filter-bar>
        <div class="people-employees__filters__sort">
          <form [formGroup]="sortForm">
            <label class="form-label">
              <input type="radio" checked formControlName="sort" value="name" />
              <span class="form-label__title">A-Z</span>
            </label>
            <label class="form-label">
              <input type="radio" formControlName="sort" value="office.name" />
              <span class="form-label__title">Location</span>
            </label>
          </form>
        </div>
      </div>
    </div>
  </aside>
  <div [class.container-xl]="isContainer">
    <ul class="row people-employees__list" [@CardGridAnimation]="items.length">
      <li class="col-12 col-md-8 people-employees__list__item" [class.col-lg-6]="cols === 4" *ngFor="let item of items | sortBy: sortBy">
        <app-employee-card [data]="item" [asContact]="asContacts"></app-employee-card>
      </li>
      <li *ngIf="items.length < 1" class="col-24 section-inset-y text-center">{{ 'employee-overview-empty-text' | fromDictionary }}</li>
    </ul>
    <!-- <div class="people-employees__loadmore button-group" (fstrInViewport)="moreEmployees($event)" [once]="false">
      <button
        class="button color-black button--border type--more-news"
        *ngIf="take < items.length"
        (click)="page = page + 1; take = take * page"
      >
        <span>More employees</span>
      </button>
    </div> -->
  </div>
</section>

<ng-content></ng-content>
