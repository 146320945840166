import * as fromPages from './employees.reducer';
import * as fromPositions from './positions.reducer';
import * as fromOffices from './offices.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'people';

export interface PeopleState {
  employees: fromPages.EmployeesState;
  positions: fromPositions.PositionsState;
  offices: fromOffices.OfficesState;
}

export const reducers: ActionReducerMap<PeopleState> = {
  employees: fromPages.EmployeeReducer,
  positions: fromPositions.PositionReducer,
  offices: fromOffices.OfficeReducer,
};

export const getPeopleState = createFeatureSelector<PeopleState>(featureKey);

export const metaReducers: MetaReducer<PeopleState>[] = !environment.production ? [] : [];
