import { createReducer, on } from '@ngrx/store';
import { Position } from '../../models';
import * as pageActions from '../actions/positions.action';
import * as utility from '../../../utility';

export interface PositionsState {
  entities: { [id: number]: Position };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: PositionsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const PositionReducer = createReducer(
  initialState,
  on(pageActions.LoadPositions, (state: PositionsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadPositionsSuccess, (state: PositionsState, { Positions }) => {
    const entities = utility.ToEntities(Positions, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadPositionsFail, (state: PositionsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getPositionsEntities = (state: PositionsState) => state.entities;
export const getPositionsLoading = (state: PositionsState) => state.loading;
export const getPositionsLoaded = (state: PositionsState) => state.loaded;
