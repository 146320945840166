import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromEmployees from '../reducers/employees.reducer';
import * as fromRoot from '../../../store';

import { Employee } from '../../models';

export const getEmployeeState = createSelector(fromFeature.getPeopleState, (state: fromFeature.PeopleState) => state.employees);

export const getEmployeeEntities = createSelector(getEmployeeState, fromEmployees.getEmployeesEntities);

export const getAllEmployees = createSelector(getEmployeeEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getAllContactEmployees = createSelector(getAllEmployees, employees => {
  return employees.filter(a => a.showOnContactPage);
});

export const getOrderedEmployees = createSelector(getAllEmployees, (Pages: Employee[]) => {
  const orderedPages = Pages.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return orderedPages;
});

export const getFilteredEmployees = createSelector(getOrderedEmployees, fromRoot.getRouterState, (employees, router) => {
  if (!router?.state?.queryParams) {
    return employees;
  }
  let filteredEmployees = [...employees];

  if (router?.state.queryParams.position) {
    const position = [router?.state.queryParams.position].flat().map(v => +v);
    if (position.length) {
      filteredEmployees = filteredEmployees.filter(a => a.positions.some(b => position.indexOf(b.id) >= 0));
    }
  }

  if (router?.state.queryParams.location) {
    filteredEmployees = filteredEmployees.filter(a => a.office?.id === +router.state.queryParams.location);
  }

  if (router?.state.queryParams.q) {
    const q = (router?.state.queryParams.q).toLowerCase();
    filteredEmployees = filteredEmployees.filter(a => a.name.toLowerCase().indexOf(q) >= 0 || a.function?.toLowerCase().indexOf(q) >= 0);
  }

  return filteredEmployees;
});

export const getEmployeesLoaded = createSelector(getEmployeeState, fromEmployees.getEmployeesLoaded);
export const getEmployeesLoading = createSelector(getEmployeeState, fromEmployees.getEmployeesLoading);
