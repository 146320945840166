import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentBlocksGuard } from '../content-block/guards/content-blocks.guard';
import { ContentPagesGuard } from '../content/guards';
import { ContentPageMetaGuard } from '../content/guards/content-page-meta.guard';
import { PeopleDetailComponent } from './containers/people-detail/people-detail.component';
import { guards } from './guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: PeopleDetailComponent,
    canActivate: [ContentPagesGuard, ContentPageMetaGuard, ContentBlocksGuard, ...guards],
    data: {
      pageId: 8,
      topbar: {
        title: 'People',
        setRow: false,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PeopleRoutingModule {}
