import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as EmployeeActions from '../actions/employees.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { EmployeesService } from '../../services';

import * as fromRoot from '../../../store';
import { PeopleState } from '../reducers';
import { Store } from '@ngrx/store';
@Injectable()
export class EmployeesEffects {
  constructor(private actions$: Actions, private PageService: EmployeesService, private store: Store<PeopleState>) {}

  LoadBugs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeActions.LoadEmployees),
      switchMap(() =>
        this.PageService.getEmployees().pipe(
          map(Employees => EmployeeActions.LoadEmployeesSuccess({ Employees })),
          catchError(error => of(EmployeeActions.LoadEmployeesFail({ error })))
        )
      )
    );
  });

  LoadContactEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeActions.LoadContactEmployees),
      switchMap(() =>
        this.PageService.getContactEmployees().pipe(
          map(Employees => EmployeeActions.LoadEmployeesSuccess({ Employees })),
          catchError(error => of(EmployeeActions.LoadEmployeesFail({ error })))
        )
      )
    );
  });
}
