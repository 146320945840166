import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { isPlatformBrowser } from '@angular/common';
import { ContentPage } from '../../../content/models';
import { ContentBlock } from '../../../content-block/models';
import { getSelectedContentBlocks } from '../../../content-block/store';
import { getSelectedContentPage } from '../../../content/store';
import { Go, SetTopbarConfig, State } from '../../../store';
import { map, withLatestFrom } from 'rxjs/operators';
import { Employee, Office, Position } from '../../models';
import { getFilteredEmployees, getOrderedOffices, getOrderedPositions } from '../../store';
import { FilterConfig } from '../../../filters/models';

@Component({
  selector: 'app-people-detail',
  templateUrl: './people-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  page$: Observable<ContentPage>;
  allBlocks$: Observable<ContentBlock[]>;
  blocks$: Observable<ContentBlock[]>;
  sliders$: Observable<ContentBlock[]>;

  employees$: Observable<Employee[]>;
  positions$: Observable<Position[]>;
  offices$: Observable<Office[]>;

  filterConfig$: Observable<FilterConfig[]>;

  @ViewChild('elList') elList: ElementRef;

  constructor(private store: Store<State>, @Inject(PLATFORM_ID) private platformId) {}

  ngOnInit(): void {
    this.page$ = this.store.select(getSelectedContentPage);
    this.allBlocks$ = this.store.select(getSelectedContentBlocks);

    this.employees$ = this.store.select(getFilteredEmployees);
    this.positions$ = this.store.select(getOrderedPositions);
    this.offices$ = this.store.select(getOrderedOffices);

    this.filterConfig$ = this.positions$.pipe(
      withLatestFrom(this.offices$),
      map(([positions, offices]) => {
        const config: FilterConfig[] = [
          {
            key: 'position',
            title: 'Position',
            multiple: true,
            items: positions.map(a => ({ title: a.title, value: a.id })),
          },
          {
            key: 'location',
            title: 'Location',
            items: offices.map(a => ({ title: a.name, value: a.id })),
          },
        ];

        return config;
      })
    );

    this.blocks$ = this.allBlocks$.pipe(map(a => a.filter(b => b.blockType === 'block--text')));
    this.sliders$ = this.allBlocks$.pipe(map(a => a.filter(b => b.blockType === 'block--gallery')));
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.getMainSize();
      }, 300);
    }
  }

  setFilter(filters: { [key: string]: string | number }) {
    // Dispatch some action hier.
    console.log(filters);
    this.store.dispatch(Go({ path: [], queryParams: filters, extras: { queryParamsHandling: 'merge' } }));
  }

  // function for large view to set nex blok - margin
  getMainSize() {
    const listItems = this.elList.nativeElement.querySelectorAll('.people-page__blocks__list__item');

    listItems.forEach((element, index) => {
      // by every third
      // if ((index + 1) % 3 === 0) {
      const prevMain = listItems[index - 1];
      if (prevMain) {
        const titleEl = prevMain.querySelector('.people-content-block__title'); // get parent main element
        const textEl = prevMain.querySelector('.people-content-block__text');
        const mainEl = prevMain.querySelector('.people-content-block__main'); // get parent main element
        const heightContent = textEl && titleEl ? prevMain.clientHeight - (titleEl.clientHeight + textEl.clientHeight) : 0;

        element.style.setProperty('--peopleMain', `${heightContent}px`);
        element.style.setProperty('--elContentHeight', `${mainEl.clientHeight}px`);
      }

      //  }
    });
  }

  ngOnDestroy(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getMainSize();
  }
}
