<!-- <article class="people-page" *ngIf="people$ | async as people"> -->
<article class="people-page spacer-bottom-l" *ngIf="page$ | async as page">
  <app-header [lazyLoading]="'eager'" [fixedImage]="true" [imageUrl]="page.imageUrl" (inView)="(true)" [intro]="page.intro" [title]="page.title"></app-header>
  <div class="people-page__blocks">
    <div class="container-fluid">
      <ul class="people-page__blocks__list" #elList>
        <li
          class="people-page__blocks__list__item"
          [ngClass]="'people-page__blocks__list__item--' + (i + 1)"
          *ngFor="let item of blocks$ | async; let i = index"
        >
          <app-people-content-block [block]="item"></app-people-content-block>
        </li>
      </ul>
    </div>
  </div>
  <app-slider-block
    class="people-page__photo-slider spacer-top-l"
    [contentBlock]="slider"
    *ngFor="let slider of sliders$ | async"
  ></app-slider-block>

  <app-people-employees
    [items]="employees$ | async"
    [filters]="filterConfig$ | async"
    (filterChange)="setFilter($event)"
  ></app-people-employees>
</article>
