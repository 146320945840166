import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromPositions from '../reducers/positions.reducer';
import { Position } from '../../models';

export const getPositionState = createSelector(fromFeature.getPeopleState, (state: fromFeature.PeopleState) => state.positions);

export const getPositionEntities = createSelector(getPositionState, fromPositions.getPositionsEntities);

export const getAllPositions = createSelector(getPositionEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedPositions = createSelector(getAllPositions, (Pages: Position[]) => {
  const orderedPages = Pages.sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return orderedPages;
});

export const getPositionsLoaded = createSelector(getPositionState, fromPositions.getPositionsLoaded);
export const getPositionsLoading = createSelector(getPositionState, fromPositions.getPositionsLoading);
