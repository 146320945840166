import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as PositionActions from '../actions/positions.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { PositionsService } from '../../services';

@Injectable()
export class PositionsEffects {
  constructor(private actions$: Actions, private PageService: PositionsService) {}

  LoadBugs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PositionActions.LoadPositions),
      switchMap(() =>
        this.PageService.getPositions().pipe(
          map(Positions => PositionActions.LoadPositionsSuccess({ Positions })),
          catchError(error => of(PositionActions.LoadPositionsFail({ error })))
        )
      )
    );
  });
}
